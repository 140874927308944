import prop from 'lodash/fp/prop';
import debounce from 'lodash/debounce';
import {
  placeholderReplaceRegex,
  htmlRegex,
  SPACER_CHARACTER_UNI,
  EDITOR_TYPES,
} from './constants';
import {
  filterVariables,
  removeNonExistentVariables,
  convertVariableHtmlToVariableElement,
  getVariablePill,
  getVariableTooltip,
  getFullDisplayName,
} from '../variablesConverter';
import { unescapeBasicEntities } from '../textConverter';
import { setCursorAfterElement } from './nx-autocomplete/utils';
import { TokenPillStyle, getTokenPillClass } from '../tokenPillHtml';
import { isTokenPillEnabled } from '../feature-flags';

/**
 * Convert pasted data to variables only
 *
 * @param {string} data The html data
 *
 * @return {string}
 */
export const convertToVariablesOnly = data => {
  const temp = convertVariableHtmlToVariableElement(data);
  const variables = temp.innerHTML.match(placeholderReplaceRegex) || [];
  return variables.join('');
};

/**
 * Convert pasted data to text
 *
 * @param {string} data The data
 * @param {bool} allowLineBreaks If line breaks should be allowed
 *
 * @return {string}
 */
export const convertToTextOnly = (data, allowLineBreaks = false) => {
  const temp = convertVariableHtmlToVariableElement(data);
  const pTags = temp.querySelectorAll('p');
  const spacer = allowLineBreaks ? '\n' : ' ';
  Array.from(pTags).forEach(i => {
    const content = i.textContent;
    i.parentNode.replaceChild(
      document.createTextNode(`${content}${spacer}`),
      i
    );
  });

  const brTags = temp.querySelectorAll('br');
  Array.from(brTags).forEach(i => {
    i.parentNode.replaceChild(document.createTextNode(spacer), i);
  });

  return temp.textContent || temp.innerText;
};

export const addNxVariable = () => {
  if (CKEDITOR.plugins.registered['nx-variable']) {
    return;
  }

  const getVariable = jsonString => JSON.parse(jsonString.slice(2, -2));

  CKEDITOR.plugins.add('nx-variable', {
    requires: 'widget',
    icons: 'nx-variable',
    hidpi: true,

    init(editor) {
      const addSpacer = debounce(() => {
        let sel = editor.getSelection();
        if (!sel) {
          return;
        }
        let ranges = sel.getRanges();

        if (!ranges[0]) {
          return;
        }

        let { startContainer } = ranges[0];

        if (
          startContainer.$.parentElement &&
          startContainer.$.parentElement.classList.contains(
            'cke_widget_nx-variable'
          )
        ) {
          startContainer = startContainer.getParent();
          setCursorAfterElement(editor, startContainer);
        }

        if (!startContainer.getChildren) {
          return;
        }

        sel = editor.getSelection();
        ranges = sel.getRanges();
        const range = ranges[0];

        const childNodes = range.startContainer.getChildren();

        const count = childNodes.count();
        if (count) {
          let last = childNodes.getItem(count - 1);

          if (last.$.nodeName === 'BR' && count > 1) {
            last = childNodes.getItem(count - 2);
          }

          // Some weird characters get added. This needs to be examined more
          if (last.type === 3 && last.$.length === 7 && count > 1) {
            last = childNodes.getItem(count - 2);
          }

          // Firefox will place the cursor in the variable
          if (
            last.$.parentElement &&
            last.$.parentElement.classList.contains('cke_widget_nx-variable')
          ) {
            last = last.getParent();
            setCursorAfterElement(editor, last);
          }

          if (
            !last.$.classList ||
            !last.$.classList.contains('cke_widget_nx-variable')
          ) {
            return;
          }

          setCursorAfterElement(editor, last);
        }
      }, 200);

      editor.on('contentDom', () => {
        // If the last item on focus is a contenteditable variable
        // then add a non space element so that the cursor isn't on the
        // right hand side
        const editable = editor.editable();
        editable.on('click', addSpacer);
        editable.on('focus', addSpacer);
      });

      let changeEventCancelled = false;

      editor.on('dragstart', () => {
        changeEventCancelled = true;
      });

      editor.widgets.on('instanceCreated', evt => {
        const widget = evt.data;
        if (widget.name !== 'nx-variable') {
          return;
        }
        widget.on('blur', addSpacer);
      });

      editor.on('paste', evt => {
        const type = prop('nxData.editorType', editor);
        if (evt.data.type === 'html' && evt.data.method === 'paste') {
          evt.stop();
          const pasteData = evt.data.dataValue;
          const data = convertVariableHtmlToVariableElement(pasteData)
            .innerHTML;
          const filtered = editor.config.disableVariables
            ? [] // Remove all variables if they are disabled
            : filterVariables(
                editor.getAllVariables(),
                evt.editor.config.variableFilterType
              ); // Filter out variables
          const html = removeNonExistentVariables(data, filtered);
          if (type === EDITOR_TYPES.INLINE) {
            let data;
            if (editor.config.variablesOnly) {
              data = convertToVariablesOnly(html);
            } else {
              data = convertToTextOnly(html, editor.config.allowLineBreaks);
            }
            evt.editor.insertText(data);
            const range = editor.getSelection().getRanges()[0];
            range.collapse();
            range.select();
            return;
          }
          const containsHtmlTags = html.match(htmlRegex);
          if (containsHtmlTags) {
            editor.insertHtml(html);
          } else {
            editor.insertText(unescapeBasicEntities(html));
          }
        }

        changeEventCancelled = false;
        editor.fire('change');
      });

      editor.on(
        'change',
        function (evt) {
          if (changeEventCancelled) {
            evt.stop();
            evt.cancel();
          }
        },
        editor,
        null,
        -999
      );

      editor.widgets.add('nx-variable', {
        pathName: 'variable',
        // We need to have wrapping element, otherwise there are issues in
        // add dialog.
        template: isTokenPillEnabled()
          ? `<span></span>`
          : `
          <span class="nx-pill-prepend">
            <span class="nx-btn-icon-wrapper">
              <svg xmlns="http://www.w3.org/2000/svg" class="nx-icon">
                <use xlink:href="#" />
              </svg>
            </span>
          </span>
          <span class="nx-pill-content"></span>
        `,

        downcast() {
          const data = JSON.stringify({
            internalName: this.data.internalName,
            displayValue: this.data.displayValue,
            source: this.data.source,
            dataType: this.data.dataType,
            subType: this.data.subType,
            isDeleted: !!this.data.isDeleted,
            ...(this.data.isOutOfScope && { isOutOfScope: true }),
            ...(this.data.isChild && { isChild: true }),
            ...(this.data.isParent && { isParent: true }),
            valuePath: this.data.valuePath,
          });
          return new CKEDITOR.htmlParser.text(`[[${data}]]`);
        },

        init() {
          let data;
          try {
            data = getVariable(this.element.getText());
          } catch (err) {
            data = {
              internalName: this.element.data('internal-name'),
              displayValue: this.element.data('display-value'),
              source: this.element.data('source'),
              dataType: this.element.data('data-type'),
              subType: this.element.data('sub-type'),
              isDeleted: !!this.element.data('is-deleted'),
              ...(this.element.data('is-out-of-scope') && {
                isOutOfScope: true,
              }),
              ...(this.element.data('is-child') && { isChild: true }),
              ...(this.element.data('is-parent') && { isParent: true }),
              valuePath: this.element.data('valuePath'),
            };
          }

          this.setData('internalName', data.internalName);
          this.setData('displayValue', data.displayValue);
          this.setData('source', data.source);
          this.setData('dataType', data.dataType);
          this.setData('subType', data.subType);
          this.setData('isDeleted', !!data.isDeleted);
          if (data.isOutOfScope) {
            this.setData('isOutOfScope', true);
          }
          if (data.isChild) {
            this.setData('isChild', true);
          }
          if (data.isParent) {
            this.setData('isParent', true);
          }
          this.setData('valuePath', data.valuePath);

          this.on('ready', () => {
            const wrapper = this.wrapper.$;
            const variable = {
              key: data.internalName,
              label: data.displayValue,
              source: data.source,
              type: data.dataType,
              subType: data.subType,
              isDeleted: data.isDeleted,
              isOutOfScope: data.isOutOfScope,
              isChild: data.isChild,
              isParent: data.isParent,
              valuePath: data.valuePath,
            };
            let tooltip;
            const sourceName = editor.getSourceDisplayName(data.source);
            if (isTokenPillEnabled()) {
              tooltip = getVariableTooltip(
                {
                  value: variable.key,
                  valuePath: variable.valuePath,
                  type: variable.type,
                  source: sourceName,
                },
                editor.config.variables
              );
            } else {
              const fullName = getFullDisplayName(
                editor.config.variables,
                variable
              );
              tooltip = `Source: ${editor.getSourceDisplayName(
                data.source
              )}, Name: ${fullName}`;
            }

            wrapper.setAttribute('aria-label', tooltip);
            wrapper.setAttribute('title', tooltip);

            const before = document.createTextNode(SPACER_CHARACTER_UNI);
            const after = document.createTextNode(SPACER_CHARACTER_UNI);
            wrapper.insertBefore(before, wrapper.firstChild);
            wrapper.appendChild(after);

            wrapper.addEventListener('click', evt => {
              evt.stopPropagation();
              evt.preventDefault();
            });
          });
        },

        data() {
          this.element.data('display-value', this.data.displayValue);
          this.element.data('internal-name', this.data.internalName);
          this.element.data('source', this.data.source);
          this.element.data('data-type', this.data.dataType);
          this.element.data('sub-type', this.data.subType);
          this.element.data('is-deleted', !!this.data.isDeleted);
          this.element.data('value-path', this.data.valuePath);
          if (isTokenPillEnabled()) {
            const classNames = getTokenPillClass({
              className: 'cke_placeholder',
              disabled: !!(this.data.isDeleted || this.data.isOutOfScope),
              isChild: this.data.isChild,
              isParent: this.data.isParent,
              pillStyle: this.data.subType
                ? TokenPillStyle.LIGHT
                : TokenPillStyle.DARK,
            }).split(' ');
            classNames.forEach(className => {
              this.element.$.classList.add(className);
            });
          } else {
            this.element.$.classList.add('nx-pill');
            this.element.$.classList.add(
              this.data.subType ? 'nx-pill-image' : 'nx-pill-info'
            );
          }
          this.element.data('is-out-of-scope', !!this.data.isOutOfScope);
          this.element.data('is-child', !!this.data.isChild);
          this.element.data('is-parent', !!this.data.isParent);

          const variable = {
            key: this.data.internalName,
            label: this.data.displayValue,
            source: this.data.source.key || this.data.source,
            type: this.data.dataType,
            subType: this.data.subType,
            isDeleted: this.data.isDeleted,
            isOutOfScope: this.data.isOutOfScope,
            isChild: this.data.isChild,
            isParent: this.data.isParent,
            valuePath: this.data.valuePath,
          };
          const pill = getVariablePill(
            variable,
            editor.resolveIcon,
            editor.getSourceDisplayName(variable.source),
            editor.config.variables
          );
          this.element.setHtml(pill);
        },
      });
    },

    afterInit(editor) {
      editor.dataProcessor.dataFilter.addRules({
        text(text, node) {
          const dtd = node.parent && CKEDITOR.dtd[node.parent.name];

          // Skip the case when placeholder is in elements like <title> or <textarea>
          // but upcast placeholder in custom elements (no DTD).
          if (dtd && !dtd.span) {
            return undefined;
          }

          return text.replace(placeholderReplaceRegex, function (match) {
            const data = getVariable(match);
            let innerElement;
            if (isTokenPillEnabled()) {
              innerElement = new CKEDITOR.htmlParser.element('span', {
                class: 'cke_placeholder',
              });
            } else {
              const deletedClass = data.isDeleted ? ' nx-deleted' : '';
              const outOfScopeClass = data.isOutOfScope
                ? ' nx-out-of-scope'
                : '';
              const classes = `cke_placeholder${deletedClass}${outOfScopeClass}`;
              innerElement = new CKEDITOR.htmlParser.element('span', {
                class: classes,
              });
            }

            // Adds placeholder identifier as innertext.
            innerElement.add(new CKEDITOR.htmlParser.text(match));
            const widgetWrapper = editor.widgets.wrapElement(
              innerElement,
              'nx-variable'
            );

            // Return outerhtml of widget wrapper so it will be placed
            // as replacement.
            return widgetWrapper.getOuterHtml();
          });
        },
      });
    },
  });
};
