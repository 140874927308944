const overflowRegex = /(auto|scroll)/;

/**
 * Get the position of cursor
 *
 * @param {CKEditor Instance} editor The CKEditor instance
 *
 * @return {Object} The x,y coordinates
 */
export const getPosition = editor => {
  const dummyElement = editor.document.createElement('span');
  editor.insertElement(dummyElement);

  // Calculate the position of the div
  let x = 0;
  let y = 0;
  let obj = dummyElement.$;
  while (obj.offsetParent) {
    x += obj.offsetLeft;
    y += obj.offsetTop;
    obj = obj.offsetParent;
  }

  x += obj.offsetLeft;
  y += obj.offsetTop;

  dummyElement.remove();

  return {
    x,
    y,
  };
};

/**
 * Remove any other nodes that contains bits of the stub
 *
 * @param {TextNode} node The next node
 * @param {Range} range The original selection range
 * @param {string} stub The string to replace
 *
 * @returns {string} The string of the TextNodes removed
 */
export const excessNodesToRemove = (node, range, stub) => {
  const foundCharacters = range.endOffset - range.startOffset;
  let removedText = '';
  let leftOver = stub.slice(foundCharacters).length;
  if (leftOver) {
    const nodesToRemove = [];
    let next = node.nextSibling;
    while (next) {
      nodesToRemove.push(next);
      leftOver -= next.textContent.length;
      next = leftOver > 0 ? next.nextSibling : null;
    }
    nodesToRemove.forEach(n => {
      removedText += n.textContent;
      n.parentNode.removeChild(n);
    });
  }

  return removedText;
};

/**
 * The RegEx to find the stub at the end of a TextNode
 */
const stubRegEx = /\[([^[[]+)?$/gi;

/**
 * Find the text node inside a tag
 *
 * @param {CKEditor.DOM.Element} tag The tag to look in
 *
 * @return {null|CKEditor.DOM.TextNode}
 */
const findTextNodeWithStubInTag = tag => {
  const children = tag.getChildren().toArray();
  for (let i = children.length - 1; i >= 0; i--) {
    const node = children[i];
    if (node.type === 3) {
      if (!node.getLength()) {
        // If it's empty remove that
        node.remove();
      } else if (node.getText().match(stubRegEx)) {
        return node;
      }
    }
  }

  return null;
};

/**
 * Get a selection from a stub
 * This is used for a full replacement on enter
 *
 * @param {CKEditor Instance} editor The editor instance
 * @param {string} stub The stub to get the range of
 * @param {Range} range The selection range
 *
 * @return {Range} The selection range.
 */
export const selectionFromStub = (editor, range) => {
  let container = range.startContainer;

  // If it's not a text node (IE sometimes) then find the text node
  if (range.startContainer.type !== 3) {
    container = findTextNodeWithStubInTag(range.startContainer);
  }

  const match = container.$.textContent.match(stubRegEx);
  const newRange = editor.createRange();
  newRange.setStart(
    container,
    container.$.textContent.length - match[0].length
  );
  newRange.setEnd(container, container.$.textContent.length);

  return newRange;
};

/**
 * Get a selection from a stub
 * This is used for a partial replacement
 *
 * @param {CKEditor Instance} editor The editor instance
 * @param {string} stub The stub to get the range of
 * @param {Range} range The selection range
 */
export const selectionFromPartialStub = (editor, stub, range) => {
  const newRange = editor.createRange();
  const length = range.startContainer.$.textContent.length;
  // For the braces, the position is after that
  if (length === 2) {
    newRange.setStart(range.startContainer, 0);
  } else {
    newRange.setStart(range.startContainer, range.startOffset - 2);
  }
  newRange.setEnd(range.startContainer, length);

  return newRange;
};

/**
 * Put the cursor at the end of TextNode
 *
 * @param {CKEditor Instance} editor The editor
 * @param {Range} range The selected range
 *
 * @returns {Range} The range of the cursor.
 */
export const setCursorAtEnd = (editor, range) => {
  const newRange = editor.createRange();
  newRange.setStart(
    range.startContainer,
    range.startContainer.$.textContent.length
  );
  newRange.setEnd(
    range.startContainer,
    range.startContainer.$.textContent.length
  );
  newRange.select();

  return newRange;
};

/**
 * Get a singular or plural string
 *
 * @param {number} count The count
 * @param {string} singular The singular string
 * @param {string} plural The plural string
 *
 * @return {string}
 */
export const pluralize = (count, singular, plural) =>
  count > 1 ? plural : singular;

/**
 * Find the scrollable parent of a node
 *
 * @param {Element} node The element
 * @param {array} ignoreScrollableParents The scrollable parents to ignore
 * @param {array} fallbackScrollableParents If these are found, they should be returned
 *
 * @return {Element}
 */
export const getScrollableParent = (
  node,
  ignoreScrollableParents = [],
  fallbackScrollableParents = []
) => {
  if (node === null) {
    return;
  }

  if (node instanceof Element === false) {
    return node;
  }

  const style = window.getComputedStyle(node);

  if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
    return node;
  }

  const valid = fallbackScrollableParents.reduce((current, item) => {
    if (current) {
      return current;
    }
    return node.classList.contains(item);
  }, false);

  if (valid) {
    return node;
  }

  return getScrollableParent(
    node.parentNode,
    ignoreScrollableParents,
    fallbackScrollableParents
  );
};

/**
 * Find a parent with a matching classname
 *
 * @param {Element} element The dom element
 * @param {RegEx} regEx The regex to search
 *
 * @return {null|Element}
 */
export const matchedParent = (element, regEx) => {
  let node = element;

  while (node && node.className) {
    if (node.className.match(regEx)) {
      return node;
    }

    node = node.parentNode;
  }

  return null;
};

/**
 * Select the start of the next node
 *
 * @param {CKEditorInstance} editor The instance of CKEditor
 *
 * @return {void}
 */
export const selectNextNodeStart = editor => {
  const next = editor
    .getSelection()
    .getRanges()[0]
    .getNextNode();
  const range = editor.createRange();
  const previous = next.getPrevious();

  if (
    next.$.parentNode.classList &&
    next.$.parentNode.classList.contains('cke_widget_element')
  ) {
    range.setStartAfter(next.getParent().getParent());
    range.setEndAfter(next.getParent().getParent());
  } else if (next.getName && next.getName() === 'br') {
    // IE adds br at the end of p tags so set it before that
    range.setStartBefore(next);
    range.setEndBefore(next);
  } else if (
    previous &&
    previous.$.classList &&
    previous.$.classList.contains('cke_widget_nx-variable')
  ) {
    range.setStartAfter(previous);
    range.setEndAfter(previous);
  } else {
    range.setStart(next, 0);
    range.setEnd(next, 0);
  }

  range.collapse();
  range.select();
};

/**
 * Set the cursor where the dummy element is inserted.
 *
 * @param {CKEditorInstance} editor The editor instance.
 *
 * @return {void}
 */
export const setCursorAtInsertion = editor => {
  const sel = editor.getSelection();
  const ranges = sel.getRanges();
  ranges[0].collapse();
  ranges[0].select();
  const dummyElement = editor.document.createElement('span');
  editor.insertElement(dummyElement);
  const range = editor.createRange();
  range.setStartBefore(dummyElement);
  range.setEndBefore(dummyElement);
  dummyElement.remove();
  range.select();
};

/**
 * Insert the cursor at the end of the element.
 *
 * @param {CKEditorInstance} editor The editor instance.
 * @param {CKEditor.Element} element The element
 *
 * @return {void}
 */
export const setCursorAfterElement = (editor, element) => {
  const startRange = editor.createRange();
  startRange.setStartAfter(element);
  startRange.setEndAfter(element);
  startRange.collapse();
  startRange.select();
};
