import { offset, getOffset } from 'caret-pos';
import {
  pluralize,
  getScrollableParent,
  matchedParent,
  getPosition,
  selectionFromStub,
} from './utils';

const NX_AUTOCOMPLETE_WRAPPER = 'nx-auto-complete-wrapper';
const NX_AUTOCOMPLETE_OPTIONS = 'nx-auto-complete-options';
const NX_AUTOCOMPLETE_OPTION = 'nx-autocomplete-option';
const NX_AUTOCOMPLETE_OPTION_SELECTED = 'nx-selected';
const NX_AUTOCOMPLETE_TYPE = 'nx-autocomplete-type';
const NX_AUTOCOMPLETE_SUMMARY = 'nx-autocomplete-summary';
const NX_AUTOCOMPLETE_SUMMARY_SHOW = 'nx-show';
const NX_AUTOCOMPLETE_WIDTH = 300;
const NX_AUTOCOMPLETE_LINE_HEIGHT = 20;
const NX_AUTOCOMPLETE_X_OFFSET = 5;
const NX_AUTOCOMPLETE_Y_OFFSET = 5;

/**
 * Menu factory
 *
 * @param {AutocompleteInstance} autocomplete The autocomplete instance
 */
export const createAutoCompleteMenu = autocomplete => {
  let optionsEl = null;
  let above = null;
  let inWindow = false;
  let scroller = null;
  let isInserting = false;
  let off = null;
  let offsetChecker = null;
  let modal = null;

  /**
   * Create the options container
   *
   * @return {void}
   */
  const createOptionsEl = () => {
    const optionsEl = document.createElement('div');
    optionsEl.id = NX_AUTOCOMPLETE_OPTIONS;
    optionsEl.className = 'nx-autocomplete-options';
    optionsEl.style.width = `${NX_AUTOCOMPLETE_WIDTH}px`;
    const div = document.createElement('div');
    div.classList.add(NX_AUTOCOMPLETE_WRAPPER);
    optionsEl.appendChild(div);
    const ul = document.createElement('ul');
    div.appendChild(ul);
    const summary = document.createElement('div');
    summary.classList.add(NX_AUTOCOMPLETE_SUMMARY);
    optionsEl.appendChild(summary);

    window.addEventListener('resize', autocomplete.removeAutocomplete);

    return optionsEl;
  };

  /**
   * Position the menu
   *
   * @return {void}
   */
  const positionMenu = () => {
    const editor = autocomplete.editor;
    const parent = editor.element.$.parentNode;

    if (!scroller) {
      modal = matchedParent(parent, new RegExp('modal-dialog-body', 'ig'));

      if (modal) {
        scroller = modal;
      } else {
        const scrollableParent = getScrollableParent(
          parent,
          editor.config.ignoreScrollableParents,
          editor.config.fallbackScrollableParents
        );
        scroller = scrollableParent ? scrollableParent.parentNode : window;

        if (
          !scroller ||
          scroller.tagName === undefined ||
          scroller.tagName === 'HTML' ||
          scroller.tagName === 'BODY'
        ) {
          scroller = window;
          inWindow = true;
        }
      }
    }

    if (editor.container.$.contentEditable === 'true') {
      return positionMenuInContentEditable();
    }

    positionMenuInIframe(modal);
  };

  /**
   * Position the menu in an inline editor
   *
   * @return {void}
   */
  const positionMenuInContentEditable = () => {
    const editor = autocomplete.editor;
    let containerOff;
    let topOffset = 0;
    let leftOffset = 0;

    // Offset of the cursor in the content editable
    offsetChecker = () => offset(editor.container.$);
    off = offsetChecker();

    leftOffset = off.left;

    // Styles to add to the calculation
    const containerStyles = window.getComputedStyle(editor.container.$);
    const paddingLeft = parseInt(containerStyles.paddingLeft, 10);
    const borderLeftWidth = parseInt(containerStyles.borderLeftWidth, 10);
    const paddingTop = parseInt(containerStyles.paddingTop, 10);

    if (inWindow) {
      topOffset += off.top + paddingTop;
      leftOffset += parseInt(containerStyles.borderLeftWidth, 10) * 2;
      topOffset += off.height - NX_AUTOCOMPLETE_Y_OFFSET;
    } else {
      // The scrollable div is not the window, calculate it's dimensions
      const scrollerOff = getOffset(scroller);
      topOffset +=
        scroller.scrollTop -
        scrollerOff.top +
        off.top +
        off.height +
        NX_AUTOCOMPLETE_Y_OFFSET;
      containerOff = getOffset(scroller);
      leftOffset = off.left - containerOff.left;
    }

    leftOffset += borderLeftWidth;
    leftOffset -= paddingLeft;

    setMenuPosition(leftOffset, topOffset);
  };

  /**
   * Position the menu to where it needs to be displayed
   *
   * @return {void}
   */
  const positionMenuInIframe = modal => {
    const editor = autocomplete.editor;
    let topOffset = 0;
    let leftOffset = 0;

    const iframe = editor.container.$.querySelector('iframe');
    // Offset of the iframe
    const containerOff = getOffset(iframe);
    // The cursor offset in the editor
    offsetChecker = () => offset(editor.document.getBody().$, { iframe });
    off = offsetChecker();
    // get the scroll top of the editor so we can adjust by it
    const parentWindow =
      editor.document.$.parentWindow || editor.document.$.defaultView;
    const scrollTop = parentWindow.pageYOffset;
    topOffset += containerOff.top;

    leftOffset = off.left;

    // Styles to add to the calculation
    const container = editor.document.getBody().$;
    const bodyStyles = window.getComputedStyle(container);
    const containerStyles = window.getComputedStyle(editor.container.$);
    const marginLeft = parseInt(bodyStyles.marginLeft, 10);
    const borderLeftWidth = parseInt(containerStyles.borderLeftWidth, 10);
    const marginTop = parseInt(bodyStyles.marginTop, 10);

    if (modal) {
      let modalOff = getOffset(modal);
      leftOffset = containerOff.left - modalOff.left + off.left;
      leftOffset -= marginLeft + borderLeftWidth;
    } else if (!inWindow) {
      leftOffset += marginLeft + borderLeftWidth;
    }

    if (inWindow) {
      topOffset += off.top + marginTop;
      leftOffset -= NX_AUTOCOMPLETE_X_OFFSET;
      topOffset += NX_AUTOCOMPLETE_Y_OFFSET;
      leftOffset += marginLeft - borderLeftWidth;
    } else {
      const scrollerOff = getOffset(scroller);
      topOffset +=
        scroller.scrollTop -
        scrollerOff.top +
        off.top +
        off.height +
        NX_AUTOCOMPLETE_Y_OFFSET -
        scrollTop;
    }

    setMenuPosition(leftOffset, topOffset);
  };

  /**
   * Check the menu position
   *
   * @return {void}
   */
  const checkPosition = () => {
    const newOff = offsetChecker();

    if (newOff.top === off.top && newOff.height === off.height) {
      return;
    }

    positionMenu();
  };

  /**
   * Set the position of the menu.
   *
   * @param {number} leftOffset The left offset
   * @param {number} topOffset The top offset
   *
   * @return {void}
   */
  const setMenuPosition = (leftOffset, topOffset) => {
    const editor = autocomplete.editor;
    if (scroller.appendChild) {
      scroller.appendChild(optionsEl);
    } else {
      let parent = editor.element.$.parentNode;
      if (parent.classList.contains('nx-link-editor-wrapper')) {
        parent = parent.parentNode.parentNode;
      }
      parent.appendChild(optionsEl);
    }

    // If the horizontal same is not large enough for the menu
    // the move it back to the left
    const space = inWindow ? window.outerWidth : scroller.clientWidth;

    if (space - leftOffset < NX_AUTOCOMPLETE_WIDTH) {
      optionsEl.style.right = '0px';
    } else {
      optionsEl.style.left = `${leftOffset}px`;
    }

    optionsEl.style.top = `${topOffset}px`;
    optionsEl.dataset.top = topOffset;
    setTimeout(() => {
      editor.focus();
    }, 100);
  };

  /**
   * Position the menu vertically where it should be
   *
   * @return {void}
   */
  const positionMenuTop = () => {
    const topOffset = parseInt(optionsEl.dataset.top, 10);
    const top = topOffset + optionsEl.offsetHeight;
    let adjust = 0;

    if (inWindow) {
      const scrollTop = window.scrollTop || window.pageYOffset;
      const windowHeight = scrollTop + window.innerHeight;
      above = windowHeight - scrollTop < top;
    } else {
      above = scroller.scrollTop + scroller.clientHeight < top;
      adjust = NX_AUTOCOMPLETE_Y_OFFSET;
    }

    if (!above) {
      optionsEl.style.top = `${topOffset}px`;
      optionsEl.style.marginTop = null;
      return;
    }

    optionsEl.style.top = `${top -
      NX_AUTOCOMPLETE_LINE_HEIGHT -
      optionsEl.offsetHeight * 2 -
      adjust}px`;
    optionsEl.style.marginTop = 'auto';
  };

  /**
   * Set the element to be selected.
   *
   * @param {Element} element The selected element
   *
   * @return {void}
   */
  const setSelected = element => {
    const hoveredOption = parseInt(element.dataset.nxOrder, 10);
    autocomplete.setSelected(hoveredOption);
    const selected = optionsEl.querySelectorAll(
      `.${NX_AUTOCOMPLETE_OPTION_SELECTED}`
    );
    Array.from(selected).forEach(item => {
      item.classList.remove(NX_AUTOCOMPLETE_OPTION_SELECTED);
    });
    element.classList.add(NX_AUTOCOMPLETE_OPTION_SELECTED);
  };

  /**
   * Show the options
   *
   * @param {Selection} selection The current selection.
   *
   * @return {void}
   */
  const showOptions = selection => {
    const editor = autocomplete.editor;
    getPosition(editor);
    autocomplete.matchOptions();

    // We want to keep the original range
    // This is where the square brackets were entered so we can position here again.
    let ranges = autocomplete.getRanges();
    if (selection && !ranges) {
      ranges = selection.getRanges();
      autocomplete.setRanges(ranges);
    }

    if (!optionsEl) {
      optionsEl = createOptionsEl();

      const range = selectionFromStub(editor, ranges[0]);
      range.select();

      autocomplete.createPill();

      positionMenu();

      optionsEl.addEventListener('mousedown', evt => {
        let target = evt.target.parentNode;
        if (evt.target.tagName === 'STRONG') {
          target = target.parentNode;
        }
        evt.stopPropagation();
        if (
          target.className === NX_AUTOCOMPLETE_OPTION ||
          (target.className ===
            `${NX_AUTOCOMPLETE_OPTION} ${NX_AUTOCOMPLETE_OPTION_SELECTED}` &&
            target.dataset.nxOptionKey)
        ) {
          isInserting = true;
          evt.preventDefault();
          autocomplete.insertVariable(target.dataset.nxOptionKey);
        }
      });

      optionsEl.addEventListener('mouseover', evt => {
        if (!evt.target.parentNode.classList.contains(NX_AUTOCOMPLETE_OPTION)) {
          return;
        }
        setSelected(evt.target.parentNode);
      });
    }

    createOptions();
  };

  /**
   * Create the options
   *
   * @return {Element} The selected element
   */
  const createOptions = () => {
    // Remove all options
    const summary = optionsEl.childNodes[1];
    const ul = optionsEl.childNodes[0].childNodes[0];
    ul.textContent = null;
    summary.textContent = null;
    summary.classList.remove(NX_AUTOCOMPLETE_SUMMARY_SHOW);
    const options = autocomplete.getOptions();
    let selectedOption = null;

    // Add all the matching ones
    if (options.length) {
      if (autocomplete.getSelected() === null) {
        autocomplete.setSelected(0);
      }
      options.forEach((option, i) => {
        const optionEl = document.createElement('li');
        optionEl.innerHTML = `
          <div>
            ${option.highlighted}
          </div>
          <div class="${NX_AUTOCOMPLETE_TYPE}">
            ${option.type}
          </div>
        `;
        optionEl.dataset.nxOptionKey = option.key;
        optionEl.dataset.nxOptionLabel = option.label;
        optionEl.dataset.nxOrder = i;

        if (autocomplete.getSelected() === i) {
          optionEl.className = `${NX_AUTOCOMPLETE_OPTION} ${NX_AUTOCOMPLETE_OPTION_SELECTED}`;
          selectedOption = optionEl;
        } else {
          optionEl.className = NX_AUTOCOMPLETE_OPTION;
        }

        ul.appendChild(optionEl);
      });
      summary.textContent = `Found ${options.length} ${pluralize(
        options.length,
        'match',
        'matches'
      )}`;
      summary.classList.add(NX_AUTOCOMPLETE_SUMMARY_SHOW);
    } else {
      const optionEl = document.createElement('li');
      optionEl.classList.add('nx-autocomplete-option');
      optionEl.innerHTML = '<div>No matching variables</div>';
      ul.appendChild(optionEl);
    }

    positionMenuTop();

    return selectedOption;
  };

  /**
   * Select the previous item
   *
   * @return {void}
   */
  const selectPrevious = () => {
    const previous = autocomplete.getSelected();
    autocomplete.setSelected(previous - 1);
    if (autocomplete.getSelected() < 0) {
      autocomplete.setSelected(autocomplete.getOptions().length - 1);
    }
    if (previous === autocomplete.getSelected()) {
      return;
    }
    const selected = optionsEl.querySelector(
      `[data-nx-order="${autocomplete.getSelected()}"]`
    );
    setSelected(selected);
    ensureOptionVisible(selected);
  };

  /**
   * Select the next item
   *
   * @return {void}
   */
  const selectNext = () => {
    const previous = autocomplete.getSelected();
    autocomplete.setSelected(previous + 1);
    if (autocomplete.getSelected() > autocomplete.getOptions().length - 1) {
      autocomplete.setSelected(0);
    }
    if (previous === autocomplete.getSelected()) {
      return;
    }
    const selected = optionsEl.querySelector(
      `[data-nx-order="${autocomplete.getSelected()}"]`
    );
    setSelected(selected);
    ensureOptionVisible(selected);
  };

  /**
   * Ensure that the option is visible
   *
   * @param {Element} selected The selected element
   *
   * @return {void}
   */
  const ensureOptionVisible = selected => {
    const wrapper = optionsEl.childNodes[0];
    const elementOffset = getOffset(selected);
    const menuOffset = getOffset(optionsEl);
    const borderWidth =
      parseInt(window.getComputedStyle(selected, null).borderBottomWidth, 10) ||
      0;
    const topY =
      elementOffset.top + wrapper.scrollTop - menuOffset.top - borderWidth;
    const bottomY = topY + selected.offsetHeight;
    const viewPort = wrapper.scrollTop + wrapper.offsetHeight;

    if (bottomY > viewPort) {
      wrapper.scrollTop = wrapper.scrollTop + (bottomY - viewPort);
    } else if (topY < wrapper.scrollTop) {
      wrapper.scrollTop = topY;
    }
  };

  /**
   * Destroy the element
   *
   * @return {void}
   */
  const destroy = () => {
    const element = document.getElementById(NX_AUTOCOMPLETE_OPTIONS);
    if (element) {
      element.parentNode.removeChild(element);
    }
    optionsEl = null;
    above = null;
    inWindow = false;
    scroller = null;
    isInserting = false;
    off = null;
    offsetChecker = null;
    modal = null;

    window.removeEventListener('resize', autocomplete.removeAutocomplete);
    window.removeEventListener('scroll', autocomplete.removeAutocomplete);
  };

  return {
    checkPosition,
    destroy,
    isInserting: () => isInserting,
    selectNext,
    selectPrevious,
    showOptions,
    $: {
      el: optionsEl,
    },
  };
};
