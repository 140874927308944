const LD_CLIENT_SYMBOL = Symbol.for('ntx-ld-client');

if (!Object.getOwnPropertySymbols(window).includes(LD_CLIENT_SYMBOL)) {
  window[LD_CLIENT_SYMBOL] = { variation: () => false, isNotLoaded: true };
}

export const setLDClient = client => {
  window[LD_CLIENT_SYMBOL] = client;
};

export const isTokenPillEnabled = () =>
  window[LD_CLIENT_SYMBOL].variation('object-variables-experience', false);
