const noop = () => {};
const pluginName = 'nx-noop';
const nxNoop = { exec: noop };

/**
 * Adds a command to not do anything. This is for ENTER key in an inline editor
 */
export const addNxNoop = () => {
  if (CKEDITOR.plugins.registered[pluginName]) {
    return;
  }
  CKEDITOR.plugins.add(pluginName, {
    init: function (editor) {
      editor.addCommand(pluginName, nxNoop);
    },
  });
};
