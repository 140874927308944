export const SPACER_CHARACTER_UNI = '\u200d'; // &zwj;
export const placeholderReplaceRegex = /\[\[\{[^&].+?(\}\]\])/g;
export const stripTagsRegex = /(<([^>]+)>)/gi;
export const htmlRegex = /(<([^>]+)>)/i;

export const iconMap = {
  text: 'string',
  string: 'string',
  number: 'decimal',
  integer: 'integer',
  decimal: 'decimal',
  object: 'function',
  array: 'collection',
  collection: 'collection',
  boolean: 'boolean',
  date: 'date-time',
  datetime: 'date-time',
  file: 'file',
};

export const EDITOR_TYPES = {
  INLINE: 'inline',
  REPLACE: 'replace',
};
