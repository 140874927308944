import {
  addNxLink,
  addNxFullScreen,
  addNxAutoComplete,
  addNxVariable,
  addNxNoop,
} from './plugins/index';

export { setLDClient } from './feature-flags';

addNxLink();
addNxFullScreen();
addNxAutoComplete();
addNxNoop();
addNxVariable();

export * from './ckeditor';
export * from './variablesConverter';
export {
  selectNextNodeStart,
  setCursorAtInsertion,
} from './plugins/nx-autocomplete/utils';
export * from './plugins/constants';
export {
  removeSpecialSymbols,
  unescapeBasicEntities,
  encodeHrefVariables,
  decodeHrefVariables,
  escapeBasicEntities,
  replacePWithBr,
} from './textConverter';

export * from './tokenPillHtml';
