const nbspRegex = /&nbsp;/gi;
const hrefRegex = /href="(.*?)"/gi;
const decodedHrefRegex = /href=".+?"(\s|>)/gi;
const encodedVariableRegex = /%5B%5B%7B.+?(%7D%5D%5D)/gi;
const urlProtocolRegex = /^((http|https):\/\/)/;
const wwwRegex = /^(www\.)/;

/**
 * Remove special characters from the string
 *
 * @param {string} text The text to filter
 *
 * @return {string}
 */
export const removeSpecialSymbols = text => {
  return text.replace(nbspRegex, ' ');
};

/**
 * Decode string in the href
 *
 * @param {string} text The text to decode
 *
 * @return {string}
 */
export const decodeHrefVariables = text => {
  return text.replace(hrefRegex, i => {
    return i.replace(encodedVariableRegex, variable => decodeURI(variable));
  });
};

/**
 * Encode variables in the href
 *
 * @param {string} text The text to encode
 *
 * @return {string}
 */
export const encodeHrefVariables = text => {
  return text.replace(decodedHrefRegex, i =>
    i.replace(/(\[\[\{.+?(\}\]\]))/gi, k => encodeURI(k))
  );
};

/**
 * Escape basic entities
 * Make sure that the & is not part of an entity
 *
 * @param {string} value The text to replace
 */
export const escapeBasicEntities = value =>
  value
    .replace(/&(?!([a-zA-Z]+|#[0-9]+|#x[0-9a-fA-F]+);)/g, '&amp;')
    .replace(/ /g, '&nbsp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');

/**
 * Unescape basic entities
 *
 * @param {string} value The text to replace
 *
 * @return {string}
 */
export const unescapeBasicEntities = value =>
  value
    .replace(/&amp;/g, '&')
    .replace(/&nbsp;/g, ' ')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>');

/**
 * Replaces <p> with <br> tags in html, removing all \n
 *
 * @param {string} data The text to replace
 *
 * @return {mixed}
 */
export const replacePWithBr = data => {
  if (!data.replace) {
    return data;
  }

  const html = data
    .replace(/<p>/gi, '')
    .replace(/<\/p>/gi, '<br>')
    .replace(/(?:\r\n|\r|\n)/g, '');
  return html;
};

export const addProtocol = url => {
  if (url.match(urlProtocolRegex)) {
    return url;
  }

  return `https://${url}`;
};

export const addSlashToWWWUrl = url => {
  if (url.match(wwwRegex)) {
    return `//${url}`;
  }

  return url;
};
