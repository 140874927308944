import prop from 'lodash/fp/prop';
import {
  optionChars,
  ESC_KEY,
  UP_KEY,
  DOWN_KEY,
  ENTER,
  TAB,
  PERIOD,
  DELETE,
  BACKSPACE,
  SQUARE_BRACKET,
  END_SQUARE_BRACKET,
  CMD_KEY,
  CTRL_KEY,
  ALT_KEY,
} from './constants';
import { createNxAutoComplete } from './controller';

const getProperties = prop('properties');

export const addNxAutoComplete = () => {
  if (CKEDITOR.plugins.registered['nx-autocomplete']) {
    return;
  }

  const nxAutoComplete = createNxAutoComplete();

  CKEDITOR.plugins.add('nx-autocomplete', {
    setVariables: (editor, variables) => {
      const autocomplete = nxAutoComplete.getInstance(editor);
      autocomplete.setVariables(variables);
    },

    getVariables: editor => {
      const autocomplete = nxAutoComplete.getInstance(editor);

      return autocomplete.getVariables();
    },

    destroyAutoComplete: () => {
      nxAutoComplete.destroyContext();
    },

    init: editor => {
      const autocomplete = nxAutoComplete.getInstance(editor);
      let selected = null;

      // If the fields only takes variables, then prevent paste
      if (editor.config.variablesOnly) {
        editor.on('paste', evt => {
          evt.cancel();
        });
      }

      editor.on('destroy', () => {
        nxAutoComplete.destroyContext();
        nxAutoComplete.removeInstance(editor);
      });

      editor.on('key', evt => {
        if (
          autocomplete.isObserving() &&
          optionChars.indexOf(evt.data.keyCode) !== -1
        ) {
          evt.cancel();
          switch (evt.data.keyCode) {
            case ESC_KEY:
            case CMD_KEY:
            case CTRL_KEY:
            case ALT_KEY:
              autocomplete.removeAutocomplete();
              break;
            case UP_KEY:
              nxAutoComplete.menu.selectPrevious(autocomplete);
              break;
            case DOWN_KEY:
              nxAutoComplete.menu.selectNext(autocomplete);
              break;
            case ENTER:
            case TAB:
              // Insert the current selected item
              setTimeout(() => {
                selected = autocomplete.getSelectedOption();
                if (!selected) {
                  return;
                }
                autocomplete.insertVariable(selected.key);
              }, 100);
              break;
            case PERIOD: {
              // Check if the previous text matches a collection
              selected = autocomplete.getSelectedOption();
              const properties = getProperties(selected);
              if (properties) {
                autocomplete.insertVariablePart(selected);
              }
              break;
            }
            case DELETE:
            case END_SQUARE_BRACKET:
              evt.cancel();
              break;
          }
        }
      });

      editor.on('contentDom', () => {
        const editable = editor.editable();

        // If we're in the middle of entering a variable and someone
        // decides to use the mouse to select, destroy the menu and
        // revert the text
        editable.attachListener(editable, 'mousedown', () => {
          if (!autocomplete.isObserving()) {
            return;
          }
          autocomplete.removeAutocomplete();
        });

        editable.attachListener(editable, 'keydown', function(evt) {
          if (evt.data.$.which === ENTER && autocomplete.isObserving()) {
            evt.data.$.preventDefault();
          }
          if (evt.data.$.which === BACKSPACE) {
            if (autocomplete.isObserving()) {
              evt.cancel();
              evt.data.preventDefault();
              const last = autocomplete.removeInput();

              // Remove the current object when it has been removed
              if (last === '.') {
                autocomplete.removeLastSelectedObject();
              }

              if (autocomplete.getInput().length < 2) {
                autocomplete.stopObserving(true);
              } else {
                const selection = this.editor.getSelection();
                nxAutoComplete.show(autocomplete, selection);
              }
            }
            nxAutoComplete.previousKey = null;
          }

          if (nxAutoComplete.breakOn(evt.data.$.which)) {
            autocomplete.stopObserving();
          }
        });

        editable.attachListener(editable, 'keypress', function(evt) {
          if (evt.data.$.which === BACKSPACE) {
            return;
          }

          // If only takes variables, then don't allow any keypresses
          if (
            editor.config.variablesOnly &&
            evt.data.$.which !== SQUARE_BRACKET &&
            !autocomplete.isObserving()
          ) {
            evt.data.preventDefault();
            evt.cancel();
          }

          if (
            (evt.data.$.which === SQUARE_BRACKET &&
              nxAutoComplete.previousKey === SQUARE_BRACKET) ||
            autocomplete.isObserving()
          ) {
            evt.data.preventDefault();
            evt.cancel();
            const selection = this.editor.getSelection();
            selection.createBookmarks2();
            autocomplete.startObserving();
            autocomplete.insertInput(String.fromCharCode(evt.data.$.which));
            nxAutoComplete.show(autocomplete, selection);
            nxAutoComplete.previousKey = null;
          } else {
            nxAutoComplete.previousKey = evt.data.$.which;
          }
        });

        editor.editable().on('click', autocomplete.removeAutocomplete);
        editor.on('blur', autocomplete.removeAutocomplete);
      });
    },
  });
};
