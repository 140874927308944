import { specialChars } from './constants';
import { createInstance } from './instance';
import { createAutoCompleteMenu } from './menu';

const timeoutDelay = 300;

/**
 * Create an instance of NxAutoComplete
 */
export const createNxAutoComplete = function() {
  let instances = [];
  let startTimeout = null;

  /**
   * Destroy the context
   *
   * @return {void}
   */
  const destroyContext = () => {
    if (startTimeout !== null) {
      clearTimeout(startTimeout);
    }

    if (nxAutoComplete.menu) {
      nxAutoComplete.menu.destroy();
      nxAutoComplete.menu = null;
    }
    nxAutoComplete.previousKey = null;
  };

  /**
   * Break on which characters
   *
   * @param {number} charCode The character code
   *
   * @return {bool} If it should break.
   */
  const breakOn = charCode => specialChars.indexOf(charCode) !== -1;

  /**
   * Show the menu
   *
   * @param {AutocompleteInstance} autocomplete The autocomplete instance
   * @param {Selection} selection The selection
   *
   * @return {void}
   */
  const show = function(autocomplete, selection) {
    if (startTimeout !== null) {
      clearTimeout(startTimeout);
    }
    if (!nxAutoComplete.menu) {
      nxAutoComplete.menu = createAutoCompleteMenu(autocomplete);
    }
    startTimeout = setTimeout(
      () => nxAutoComplete.menu.showOptions(selection),
      timeoutDelay
    );
  };

  /**
   * Create an instance
   *
   * @param {CKEditor Instance} editor The editor instance
   *
   * @return {AutocompleteInstance} The autocomplete instance
   */
  const create = editor => {
    const instance = createInstance(nxAutoComplete, editor);

    instances.push({
      id: editor.id,
      instance,
    });

    return instances[instances.length - 1].instance;
  };

  /**
   * Get an instance
   *
   * @param {CKEditor Instance} editor The editor instance
   *
   * @return {AutocompleteInstance} The autocomplete instance
   */
  const getInstance = editor => {
    const instance = instances.find(i => i.id === editor.id);

    if (instance) {
      return instance.instance;
    }

    return create(editor);
  };

  /**
   * Remove an instance
   *
   * @param {CKEditor Instance} editor The editor instance
   *
   * @return {void}
   */
  const removeInstance = editor => {
    instances = instances.filter(i => i.id !== editor.id);
  };

  const nxAutoComplete = {
    breakOn,
    destroyContext,
    getInstance,
    menu: null,
    previousKey: null,
    removeInstance,
    show,
  };

  return nxAutoComplete;
};
