export const addNxFullScreen = () => {
  if (CKEDITOR.plugins.registered['nx-fullscreen']) {
    return;
  }
  CKEDITOR.plugins.add('nx-fullscreen', {
    init: editor => {
      editor.ui.addButton('NxFullScreen', {
        label: 'Full screen',
        command: 'nxfullscreen',
        toolbar: 'nxfullscreen',
      });

      editor.addCommand('nxfullscreen', {
        exec: function(editor) {
          editor.fire('fullScreen', null, editor);
        },
      });
    },
  });
};
