// This file must be kept in sync with react-core/TokenPill
// It's not being imported because we don't want to add react-core dependencies to ckeditor

import cn from 'classnames';

export const TokenPillStyle = {
  LIGHT: 0, // blue border, white icon background
  DARK: 1, // blue border, blue icon background
  FUNCTION_VARIABLE: 2, // green border, white icon background
};

const pillStyleClassName = {
  [TokenPillStyle.LIGHT]: 'nx-token-pill-light',
  [TokenPillStyle.DARK]: 'nx-token-pill-dark',
  [TokenPillStyle.FUNCTION_VARIABLE]: 'nx-token-pill-fx',
};

export const TokenPillLayout = {
  // single line pill
  SIMPLE: 0,

  // stretch vertically to fit up to 2 lines of content
  MULTI_LINE: 1,

  // pill breaks to wrap onto multiple lines
  INLINE: 2,
};

export const getTokenPillIconSvgHtml = iconHref =>
  `<svg class="nx-token-pill-icon-36x36" xmlns="http://www.w3.org/2000/svg"><use href="${iconHref}" /></svg>`;

export const getTokenPillIconImageHtml = iconSrc =>
  `<img class="nx-token-pill-icon" src="${iconSrc}">`;

export const getTokenPillInnerHtml = ({
  content = '',
  isChild = false,
  isParent = false,
  iconRef = '#variable',
}) => {
  let iconHtml;
  if (iconRef.startsWith('#')) {
    iconHtml = getTokenPillIconSvgHtml(iconRef);
  } else {
    iconHtml = getTokenPillIconImageHtml(iconRef);
  }
  return `<span class="nx-token-pill-start"><span class="nx-token-pill-icon-wrapper">&zwj;${iconHtml}</span>${
    isChild
      ? `<span class="nx-token-pill-parent-arrow">&nbsp;&nbsp;</span>`
      : ''
  }&zwj;</span><span class="nx-token-pill-content">${content}</span>&zwj;${
    isParent
      ? `<span class="nx-token-pill-child-arrow">&nbsp;&nbsp;</span>`
      : ''
  }`;
};

export const getTokenPillClass = ({
  className = undefined,
  pillLayout = TokenPillLayout.INLINE,
  disabled = false,
  isChild = false,
  isParent = false,
  pillStyle = TokenPillStyle.DARK,
}) =>
  cn('nx-token-pill', className, pillStyleClassName[pillStyle], {
    'nx-token-pill-multiline': pillLayout === TokenPillLayout.MULTI_LINE,
    'nx-token-pill-inline': pillLayout === TokenPillLayout.INLINE,
    'nx-token-pill-child': isChild,
    'nx-token-pill-parent': isParent,
    'nx-token-pill-disabled': disabled,
  });
