import {
  placeholderReplaceRegex,
  stripTagsRegex,
  SPACER_CHARACTER_UNI,
  iconMap,
} from './constants';

import { initializeInlineEditor } from '../ckeditor';

import {
  escapeBasicEntities,
  unescapeBasicEntities,
  addSlashToWWWUrl,
} from '../textConverter';

const EDITOR_HEADER_CLASS = 'cke_top';
const LINK_FORM_CLASS = 'nx-link-form';
const LINK_FORM_INLINE_CLASS = 'nx-link-form-inline';

const LINK_URL_TEXT = 'URL';
const LINK_TEXT_TEXT = 'Text';
const LINK_CANCEL_TEXT = 'Cancel';
const LINK_OK_TEXT = 'OK';
const VARIABLE = 'Insert';
const URL_PLACEHOLDER_TEXT = 'URL eg. https://www.nintex.com/';
const TEXT_PLACEHOLDER_TEXT = '';
let editorConfig = {};

const getAddVariableButton = elementName => `
<div title="${VARIABLE}" class="nx-add-variable-button" data-element-name="${elementName}">
   <svg xmlns="http://www.w3.org/2000/svg" focusable="false" class="nx-icon">
      <use xlink:href="#add-variable"></use>
   </svg>
   <div class="nx-add-variable-button-text">${VARIABLE}</div>
</div>`;

const OLD_STYLES_MODE_LINK_FORM_TEMPLATE = `
  <div class="${LINK_FORM_CLASS} nx-dark hyperlink-inlay old-styles-mode form-horizontal">
    <div class="form-group">
      <label for="link-url">${LINK_URL_TEXT}</label>
      <div class="nx-variable-input">
        ${getAddVariableButton('link-url')}
        <div class="variable-input-editor nx-link-editor-wrapper">
          <textarea name="link-url" id="link-url" contenteditable="true" class="link-url"></textarea>
        </div>
      </div>
    </div>
    <div class="form-group">
        <label for="text">${LINK_TEXT_TEXT}</label>
        <div class="nx-variable-input">
        ${getAddVariableButton('link-text')}
          <div class="variable-input-editor nx-link-editor-wrapper">
            <textarea name="link-text" id="link-text" contenteditable="true" class="form-control link-text"></textarea>
          </div>
        </div>
    </div>
    <div class="inlay-actions">
      <button class="btn btn-inverse">${LINK_CANCEL_TEXT}</button>
      <button class="btn btn-primary">${LINK_OK_TEXT}</button></div>
  </div>
`;

const getNewTemplate = config => {
  const inline = config.inlineLinkForm ? LINK_FORM_INLINE_CLASS : '';

  const shouldAddVariableButton = (linkText = '') =>
    config.disableVariables ? '' : getAddVariableButton(linkText);

  const LINK_WRAPPER_CLASS = config.linkWrapperClass || '';

  return `
    <div class="${LINK_FORM_CLASS} nx-dark nx-has-variables ${inline}">
      <div class="form-group">
        <label for="link-url">${LINK_URL_TEXT}</label>
        <div class="nx-variable-input">
        ${shouldAddVariableButton('link-url')}
          <div class="variable-input-editor nx-link-editor-wrapper ${LINK_WRAPPER_CLASS}">
            <textarea name="link-url" id="link-url" contenteditable="true" class="form-control link-url"></textarea>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="text">${LINK_TEXT_TEXT}</label>
        <div class="nx-variable-input">
        ${shouldAddVariableButton('link-text')}
          <div class="variable-input-editor nx-link-editor-wrapper ${LINK_WRAPPER_CLASS}">
            <textarea name="link-text" id="link-text" contenteditable="true" class="form-control link-text"></textarea>
          </div>
        </div>
      </div>
      <div class="actions">
        <button class="btn btn-inverse">${LINK_CANCEL_TEXT}</button>
        <button class="btn btn-primary">${LINK_OK_TEXT}</button>
      </div>
    </div>
  `;
};

const getNoVariableLinkForm = config => {
  const inline = config.inlineLinkForm ? LINK_FORM_INLINE_CLASS : '';
  const urlPlaceholder = config.urlPlaceholderText
    ? config.urlPlaceholderText
    : '';
  const textPlaceholder = config.textPlaceholderText
    ? config.textPlaceholderText
    : '';

  return `
    <div class="${LINK_FORM_CLASS} nx-dark ${inline}">
      <div class="form-group">
        <label for="link-url">${LINK_URL_TEXT}</label>
        <div class="nx-variable-input">
          <div class="variable-input-editor nx-link-editor-wrapper">
            <input name="link-url" id="link-url" class="form-control link-url" placeholder="${urlPlaceholder}"></input>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="text">${LINK_TEXT_TEXT}</label>
        <div class="nx-variable-input">
          <div class="variable-input-editor nx-link-editor-wrapper">
            <input name="link-text" id="link-text" class="form-control link-text" placeholder="${textPlaceholder}"></input>
          </div>
        </div>
      </div>
      <div class="actions">
        <button class="btn btn-inverse">${LINK_CANCEL_TEXT}</button>
        <button class="btn btn-primary">${LINK_OK_TEXT}</button>
      </div>
    </div>
  `;
};

/**
 * Insert a link into the dom
 *
 * @param {CKEditor} editor The editor instance
 * @param {string} url The url
 * @param {string} text The text
 */
const insertLink = (editor, url, text) => {
  const newLink = editor.document.createElement('a');
  newLink.$.dataset.ckeSavedHref = encodeURL(url);
  newLink.$.href = encodeURL(url);
  newLink.$.target = '_blank';
  editor.insertElement(newLink);

  const range = editor.createRange();
  range.selectNodeContents(newLink);
  range.select();
  editor.insertText(text);
  range.setStartAfter(newLink);
  range.setEndAfter(newLink);
  range.collapse();
  range.select();
};

const encodeURL = url => {
  // first decode the url before encoding it to avoid double encoding. Ex Avoid http://www.link%20with%20space.com becoming http://www.link%2520with%2520space.com
  return encodeURI(decodeURI(url));
};

const getElementByClassName = (editor, className) => {
  return editor.container.$.getElementsByClassName(className)[0];
};

const getExistingLinkForm = editor =>
  getElementByClassName(editor, LINK_FORM_CLASS);

const removeExistingDomElement = element => {
  if (!element) {
    return;
  }
  element.parentElement.removeChild(element);
};

const getSelectedLink = editor => CKEDITOR.plugins.link.getSelectedLink(editor);

const getVariablesFromLinkText = (element, text) => {
  const variables = Array.from(
    element.querySelectorAll('.cke_placeholder')
  ).map(i => ({
    internalName: i.dataset.internalName,
    dataType: i.dataset.dataType,
    subType: i.dataset.dataType,
    displayValue: i.dataset.displayValue,
    source: i.dataset.source,
    isDeleted: i.dataset.isDeleted,
    valuePath: i.dataset.valuePath,
  }));

  return text.replace(placeholderReplaceRegex, function () {
    return `[[${JSON.stringify(variables.shift())}]]`;
  });
};

const getLinkFormTemplate = config => {
  if (!config.linkVariables) {
    return getNoVariableLinkForm(config);
  }
  const useOldStylesMode = config.useOldStylesMode || false;
  return useOldStylesMode
    ? OLD_STYLES_MODE_LINK_FORM_TEMPLATE
    : getNewTemplate(config);
};

const getTextFromLink = (link, editor) => {
  if (!link || !link.$) {
    const sel = editor.getSelection();
    const ranges = sel.getRanges();
    const el = editor.document.createElement('div');
    el.append(ranges[0].cloneContents());
    return el.getHtml();
  }

  const tempElement = document.createElement('p');
  const variables = editor.getAllVariables().map(v => ({
    name: v.key,
    displayName: v.label,
    dataType: v.type,
    source: {
      label: editor.getSourceDisplayName(v.source.key),
      key: v.source.key,
    },
    isDeleted: v.isDeleted,
    valuePath: v.valuePath,
  }));
  const tempEditor = initializeInlineEditor(tempElement, {
    variables,
  });
  tempEditor.setData(link.$.innerHTML);
  const data = tempEditor.getData();
  if (tempEditor) {
    if (tempEditor.ready) {
      tempEditor.destroy();
    } else {
      tempEditor.removeAllListeners();
      CKEDITOR.remove(tempEditor);
    }
  }
  return data;
};

const setEditorConfig = config => {
  editorConfig = config;
};

const getEditorConfig = () => {
  return editorConfig;
};

const showLinkForm = editor => {
  setEditorConfig(editor.config);

  if (getExistingLinkForm(editor)) {
    return;
  }

  editor.setReadOnly(true);
  editor.fire('linkPanel', { showing: true });

  const linkFormTemplate = getLinkFormTemplate(editor.config);

  const header = editor.container.$.getElementsByClassName(
    EDITOR_HEADER_CLASS
  )[0];
  header.insertAdjacentHTML('afterend', linkFormTemplate);

  const link = getSelectedLink(editor);
  const useVariables = editor.config.linkVariables;

  const linkText = getTextFromLink(link, editor);
  let linkTextWithVariables = useVariables
    ? link && getVariablesFromLinkText(link.$, linkText)
    : link && link.$.textContent;

  if (!link) {
    linkTextWithVariables = unescapeBasicEntities(linkText).trim();
  }

  const text = linkTextWithVariables || '';

  let linkEditor;
  let textEditor;

  const textElement = getElementByClassName(editor, 'link-text');
  if (useVariables) {
    textEditor = initializeInlineEditor(textElement, {
      variables: editor.getAllVariables(),
      variableFilterType: editor.config.variableFilterType,
      placeholder: editor.config.textPlaceholderText || TEXT_PLACEHOLDER_TEXT,
      iconMap,
      resolveIcon: editor.resolveIcon,
    });
    textEditor.on('instanceReady', () => {
      textEditor.container.$.classList.add('nx-show');
      textEditor.on('focus', args => {
        editor.fire('linkEditorFocused', args, textEditor);
      });
    });
    editor.textEditor = textEditor;
    textEditor.setData(text);
  } else {
    textElement.value = text;
  }

  const urlElement = getElementByClassName(editor, 'link-url');
  let url = link && link.$.getAttribute('href');
  url = decodeURI(url || '');
  if (useVariables) {
    linkEditor = initializeInlineEditor(urlElement, {
      variables: editor.getAllVariables(),
      variableFilterType: editor.config.variableFilterType,
      placeholder: editor.config.urlPlaceholderText || URL_PLACEHOLDER_TEXT,
      iconMap,
      resolveIcon: editor.resolveIcon,
    });
    linkEditor.on('instanceReady', () => {
      linkEditor.container.$.classList.add('nx-show');
      linkEditor.on('focus', args => {
        editor.fire('linkEditorFocused', args, linkEditor);
      });
    });
    editor.linkEditor = linkEditor;
    linkEditor.setData(escapeBasicEntities(url));
  } else {
    urlElement.value = url;
  }

  const addButtons = Array.from(
    editor.container.$.getElementsByClassName('nx-add-variable-button')
  );
  const editorMap = {
    'link-text': textEditor,
    'link-url': linkEditor,
  };

  addButtons.forEach(b =>
    b.addEventListener('click', function () {
      const currentEditor = editorMap[b.dataset.elementName];
      currentEditor.focus();
      const range = currentEditor.getSelection().getRanges()[0];
      const args = {
        editor: currentEditor,
        range,
      };

      editor.fire('addVariables', args, editor);
    })
  );

  header.parentElement
    .querySelector('.btn-inverse')
    .addEventListener('click', function () {
      if (linkEditor) {
        linkEditor.destroy();
      }
      if (textEditor) {
        textEditor.destroy();
      }
      removeExistingDomElement(getExistingLinkForm(editor));
      editor.setReadOnly(false);
      editor.fire('linkPanel', { showing: false });
      editor.focus();
    });

  header.parentElement
    .querySelector('.btn-primary')
    .addEventListener('click', function () {
      const urlData = useVariables ? linkEditor.getData() : urlElement.value;
      let url = urlData && urlData.replace(stripTagsRegex, '');
      const textData = useVariables ? textEditor.getData() : textElement.value;
      const safeTextData = textData && textData.replace(stripTagsRegex, '');
      let text = safeTextData || url;
      editor.setReadOnly(false);
      editor.fire('linkPanel', { showing: false });

      if (!url && !text) {
        removeExistingDomElement(getExistingLinkForm(editor));
        editor.focus();
        return;
      }

      text = text.replace(new RegExp(SPACER_CHARACTER_UNI, 'g'), '');
      text = unescapeBasicEntities(text);
      url = url || '#';
      url = url.replace(new RegExp(SPACER_CHARACTER_UNI, 'g'), '');
      url = unescapeBasicEntities(url);
      url = getEditorConfig().fixWWWUrl ? addSlashToWWWUrl(url) : url;

      if (link) {
        const newRange = editor.createRange();
        newRange.setStartBefore(link);
        newRange.setEndAfter(link);
        newRange.select();
      }

      insertLink(editor, url, text);

      if (linkEditor) {
        editor.linkEditor = null;
        linkEditor.destroy();
      }
      if (textEditor) {
        editor.textEditor = null;
        textEditor.destroy();
      }
      removeExistingDomElement(getExistingLinkForm(editor));
    });
};

export const addNxLink = () => {
  if (CKEDITOR.plugins.registered['nx-link']) {
    return;
  }
  CKEDITOR.skin.name = 'nintex';
  CKEDITOR.config.skin = 'nintex';
  CKEDITOR.disableAutoInline = true;

  CKEDITOR.plugins.add('nx-link', {
    init: editor => {
      editor.on(
        'doubleclick',
        e => {
          if (
            e.data.element.hasClass('cke_placeholder') ||
            e.data.element.$.nodeName === 'A'
          ) {
            e.cancel();
          }
        },
        null,
        null,
        -1
      );

      editor.ui.addButton('NxLink', {
        label: 'Link',
        command: 'nxlink',
        toolbar: 'nxlink',
      });

      editor.addCommand('nxlink', {
        exec(editor) {
          showLinkForm(editor);
        },
      });
    },
  });
};
