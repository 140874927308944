import escapeRegExp from 'lodash/fp/escapeRegExp';
import last from 'lodash/fp/last';
import { NX_VARIABLE_TYPE_OBJECT } from './constants';
import { selectNextNodeStart } from './utils';

/**
 * Create an instance
 *
 * @param {NxAutoComplete} nxAutoComplete The instance of the controller
 * @param {CKEditor Instance} editor The editor instance
 *
 * @returns {Object} The instance.
 */
export const createInstance = function (nxAutoComplete, editor) {
  let variables = [];
  let observing = false;
  let input = [];
  let options = [];
  let ranges = null;
  let selected = null;
  let selectedObjects = [];
  let hasCalledReset = false;
  let pill = null;

  /**
   * Match options to the current stub
   *
   * @return {Array} The array of options
   */
  const matchOptions = function () {
    selected = 0;
    options = [].concat(variables);
    let replaceText = '[[';
    if (selectedObjects.length) {
      const labels = selectedObjects.map(i => i.label).join('.');
      options = last(selectedObjects).properties;
      replaceText += `${labels}.`;
    }
    const term = input.join('').replace(replaceText, '');

    if (!term) {
      options = options.map(i => ({ ...i, highlighted: i.label }));
      return options;
    }

    const pattern = new RegExp(`(${escapeRegExp(term)})`, 'ig');
    const startPattern = new RegExp(`^${escapeRegExp(term)}`, 'ig');
    options = options
      .filter(i => i.label.match(pattern))
      .map(i => {
        i.highlighted = term.length
          ? i.label.replace(pattern, '<strong>$1</strong>')
          : i.label;
        return i;
      })
      .sort((a, b) => {
        const aMatch = a.label.match(startPattern);
        const bMatch = b.label.match(startPattern);
        const aLength = aMatch !== null ? aMatch.length : 0;
        const bLength = bMatch !== null ? bMatch.length : 0;
        if (aLength > bLength) {
          return -1;
        }

        if (aLength < bLength) {
          return 1;
        }

        return 0;
      });

    return options;
  };

  /**
   * Get the option with a matching id
   *
   * @param {string} optionId The option id
   *
   * @return {object} The matched option
   */
  const getOption = function (optionId) {
    let options = [].concat(variables);
    if (selectedObjects.length) {
      options = last(selectedObjects).properties;
    }

    return options.find(i => i.key === optionId);
  };

  /**
   * Insert a part of a multipart variable
   *
   * @param {AutocompleteInstance} autocomplete The auto complete instance
   * @param {Object} option The option to insert
   *
   * @return {void}
   */
  const insertVariablePart = function (option) {
    let labels = selectedObjects.map(i => i.label).join('.');
    if (selectedObjects.length) {
      labels += '.';
    }
    input = [].concat(`[[${labels}`.split(''), option.label.split(''), ['.']);
    setPillContent(false);

    selectedObjects.push(option);
    setTimeout(nxAutoComplete.menu.checkPosition, 500);
    nxAutoComplete.show(this);
    selected = 0;
  };

  /**
   * Insert a placeholder into the editor
   *
   * @param {AutocompleteInstance} autocomplete The auto complete instance
   * @param {Object} option The option to insert
   *
   * @return {void}
   */
  const insertPlaceholder = function (option) {
    let { label } = option;
    const { source } = option;
    if (source) {
      const variable = {
        internalName: option.parent || option.key,
        displayValue: option.label,
        source: source.key,
        dataType: option.type,
        subType: option.subType,
        isDeleted: option.isDeleted,
        ...(option.isOutOfScope && { isOutOfScope: true }),
        ...(option.isParent && { isParent: true }),
        ...(option.isChild && { isChild: true }),
      };

      if (selectedObjects.length) {
        const key = selectedObjects.length > 1 ? `${option.key}` : option.key;
        variable.valuePath = `$['${selectedObjects
          .slice(1)
          .map(o => o.key)
          .join("']['")}']['${key}']`.replace("['']", '');
      }

      label = JSON.stringify(variable);
    }
    const range = editor.createRange();
    range.setStartBefore(pill);
    range.setEndAfter(pill);
    range.deleteContents();
    pill = null;
    editor.insertText(`[[${label}]]`);
    selectNextNodeStart(editor);
  };

  /**
   * Insert a variable when ENTER or TAB is pressed.
   *
   * @param {string} optionId The option id
   * @param {AutocompleteInstance} autocomplete The autocomplete instance.
   *
   * @return {void}
   */
  const insertVariable = function (optionId) {
    const option = this.getOption(optionId);

    // Prevents enter being hit too many times too quickly
    if (!option) {
      return;
    }

    if (option.type === NX_VARIABLE_TYPE_OBJECT) {
      this.insertVariablePart(option);
      return;
    }

    insertPlaceholder(option);
    this.stopObserving();
    nxAutoComplete.previousKey = null;
  };

  /**
   * Start observing
   *
   * @return {void}
   */
  const startObserving = function () {
    if (!observing) {
      input.push('[');
    }
    observing = true;
  };

  /**
   * Stop observing
   *
   * @param {bool} removeStub If the stub should be removed
   *
   * @return {void}
   */
  const stopObserving = function (removeStub = false) {
    if (hasCalledReset) {
      return;
    }
    hasCalledReset = true;
    // Remove the text entered by the user
    // used when a cancel event has be fired
    if (removeStub) {
      const range = editor.createRange();
      range.setStartBefore(pill);
      range.setEndAfter(pill);
      range.select();
      range.deleteContents();
      pill = null;

      if (input.length === 2 && !editor.config.variablesOnly) {
        editor.insertText(input.join(''));
      }
    }

    // For cancellation
    editor.fire('change');

    observing = false;
    input = [];
    options = [];
    selected = null;
    ranges = null;
    selectedObjects = [];
    hasCalledReset = false;
    pill = null;
    nxAutoComplete.destroyContext();
  };

  /**
   * Remove the autocomplete
   * Used for escaping current entry
   */
  const removeAutocomplete = () => {
    if (!observing || nxAutoComplete.menu.isInserting()) {
      return;
    }
    stopObserving(true);
  };

  /**
   * Set the pill content
   *
   * @param {boolean} checkPosition If the position should be checked
   *
   * @return {void}
   */
  const setPillContent = (checkPosition = true) => {
    if (!pill) {
      return;
    }

    pill.$.textContent = input.join('');

    if (checkPosition) {
      nxAutoComplete.menu.checkPosition();
    }
  };

  /**
   * Add to the input stack
   *
   * @param {string} value The character to insert
   *
   * @return {void}
   */
  const insertInput = value => {
    input.push(value);
    setPillContent();
  };

  /**
   * Remove from the input stack
   *
   * @return {void}
   */
  const removeInput = () => {
    const value = input.pop();
    setPillContent();

    return value;
  };

  /**
   * Create a insert pill
   *
   * @return {void}
   */
  const createPill = () => {
    pill = editor.document.createElement('span');
    pill.$.classList.add('nx-variable-highlight');
    pill.$.textContent = input.join('');
    editor.insertElement(pill);
  };

  return {
    selectedObjects: () => selectedObjects,
    editor,
    getInput: () => input,
    getOption,
    getOptions: () => options,
    getRanges: () => ranges,
    getSelected: () => selected,
    getSelectedOption: () => options[selected],
    matchOptions,
    insertInput,
    insertVariable,
    insertVariablePart,
    isObserving: () => observing,
    removeAutocomplete,
    removeLastSelectedObject: () => selectedObjects.pop(),
    removeSelectedObjects: () => (selectedObjects = []),
    removeInput,
    setSelected: value => (selected = value),
    setRanges: value => (ranges = value),
    setVariables: list => (variables = list),
    getVariables: () => variables,
    createPill,
    stopObserving,
    startObserving,
  };
};
