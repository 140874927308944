export const SQUARE_BRACKET = 91;
export const END_SQUARE_BRACKET = 221;
export const BACKSPACE = 8;
export const ENTER = 13;
export const TAB = 9;
export const LEFT_KEY = 37;
export const RIGHT_KEY = 39;
export const UP_KEY = 38;
export const DOWN_KEY = 40;
export const DELETE = 46;
export const HOME_END = 91;
export const PERIOD = 190;
export const ESC_KEY = 27;
export const CTRL_KEY = 1114129;
export const ALT_KEY = 4456466;
export const CMD_KEY = 1114203;

export const specialChars = [HOME_END];

export const optionChars = [
  UP_KEY,
  DOWN_KEY,
  LEFT_KEY,
  RIGHT_KEY,
  TAB,
  ENTER,
  PERIOD,
  ESC_KEY,
  DELETE,
  END_SQUARE_BRACKET,
  CTRL_KEY,
  ALT_KEY,
  CMD_KEY,
];

export const NX_VARIABLE_TYPE_OBJECT = 'Object';

export const TYPE_DATE_TIME = 'DateTime';
export const TYPE_OBJECT = 'Object';
export const TYPE_STRING = 'Text';
export const TYPE_INTEGER = 'Integer';
export const TYPE_NUMBER = 'Number';
export const TYPE_COLLECTION = 'Collection';

export const iconText = {
  [TYPE_DATE_TIME]: 'DT',
  [TYPE_OBJECT]: '{<span>&#8230;</span>}',
  [TYPE_STRING]: 'S',
  [TYPE_INTEGER]: 'I',
  [TYPE_NUMBER]: 'N',
  [TYPE_COLLECTION]: 'F',
};
